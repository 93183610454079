<template>
    <div class="contaners_share">
        <img style="width:100%;height:100%" src="../../assets/backgroundicon.png"/>
        <div class="content_share1">
            <div @click="mineMessage" class="messageTitle12">
                <img class="messageIcon" src="../../assets/phone2.png" />
                <div>联系客服</div>
            </div>
            <div class="title_share">尊敬的会员您好:</div>
            <div class="share_box">
                <div>{{settingDate.config.shareholder_explain}}</div>
                <!-- <div>成为利道工会股东将享受股东专属权益，可持有的最大股份由您购购账户资产数量决定。在整个股东申请中所填写的资料将作为合同的数据基础，需填写真实有效的资料信息。信息相关资料我方将加密存储，保证信息安全</div> -->
                <!-- <div>基本流程为： 1.合同申请——2.信息确认——3.合同填写，扫描回传——4.审核】</div> -->
            </div>
            <div class="selectBtn" v-if="settingDate.shareholder_activity!=null">
                <van-radio-group v-model="checked" direction="horizontal">
                    <van-radio icon-size="20px" name="1"><div class="text_color">分享式</div></van-radio>
                    <van-radio icon-size="20px" name="2"><div class="text_color">置换式</div></van-radio>
                </van-radio-group>
            </div>
            <div class="share_text"  v-if="selectShow==1" >线上股东报名:</div>
            <div class="share_text"  v-if="selectShow==2" >线下股东报名:</div>
        <!--<div v-if="!settingDate.shareholder_activity">
                    <div class="share_text1" v-if="checked==1">一.申请者购购账户所持有补金大于等于{{settingDate.config.shareholder_assets_1}} </div>
                    <div class="share_text1"  v-if="checked==2">一.申请者购购账户所持有补金大于等于{{settingDate.config.shareholder_assets_2}} </div>
                    <div class="share_text1"  v-if="checked==1">二.申请者购购账户下级数量大于等于{{settingDate.config.shareholder_inviter_1}} </div>
                    <div class="share_text1"  v-if="checked==2">2.申请者购购账户下级数量大于等于{{settingDate.config.shareholder_inviter_2}} </div>
                    <div class="share_text1"  v-if="checked==1">三.{{settingDate.config.shares_cardinal_1}}补金=1工分 </div>
                    <div class="share_text1"  v-if="checked==2">二.{{settingDate.config.shares_cardinal_2}}补金=1工分 </div>
            </div>-->

            <div v-if="settingDate.shareholder_activity!=null">
                    <div class="share_text1" v-if="checked==1">一.申请者购购账户所持有补金大于等于{{settingDate.shareholder_activity.activity_assets_1}} </div>
                    <div class="share_text1"  v-if="checked==2">一.申请者购购账户所持有补金大于等于{{settingDate.shareholder_activity.activity_assets_2}} </div>
                    <div class="share_text1"  v-if="checked==1">二.申请者购购账户下级数量大于等于{{settingDate.shareholder_activity.activity_inviter_1}} </div>
                    <!-- <div class="share_text1"  v-if="checked==2">2.申请者购购账户下级数量大于等于{{settingDate.shareholder_activity.activity_inviter_2}} </div> -->
                    <div class="share_text1"  v-if="checked==1">三.{{settingDate.shareholder_activity.shares_cardinal_1}}补金=1工分 </div>
                    <div class="share_text1"  v-if="checked==2">二.{{settingDate.shareholder_activity.shares_cardinal_2}}补金=1工分 </div>
            </div>
            
            <div class="select_share">
                <img src="../../assets/share1.png"/>
                <div class="share_text2">线上股东报名</div>
                <div class="select_img" @click="selectBtn(1)"><div>选择</div><img v-if="selectShow==1" class="select_color" src="../../assets/successIcon2.png"/></div>
            </div>
          <!--  <div class="select_share">
                <img src="../../assets/share1.png"/>
                <div class="share_text2">线下股东报名</div>
                <div class="select_img"  @click="selectBtn(2)">
                    <div>选择</div>
                    <img v-if="selectShow==2" class="select_color" src="../../assets/successIcon2.png"/>
                </div>
            </div>-->
            <!-- 分享式 -->
            <div v-if="checked==1">
                <div v-if="!settingDate.shareholder_activity">
                     <!-- 判断是否达标 -->
                     
                     <div v-if="(Number(userFrom.member.available_assets)<Number(settingDate.config.shareholder_assets_1))&&(Number(settingDate.inviter_count)<Number(settingDate.config.shareholder_inviter_1))">
                         <div>
                            <div style="margin-top:20px;color:#fff" class="infoText">经系统检测,您当前不满足申请条件,无法申请</div>
                            <div class="share_btn bg" style="background:#eee">
                                <div>下一步</div>
                            </div>
                        </div>
                     </div>
                     <div v-else>
                         <div  v-if="Number(userFrom.member.available_assets)<Number(settingDate.config.shareholder_assets_1)">
                            <div style="margin-top:20px;color:#fff" class="infoText">经系统检测,您当前补金不足,无法申请</div>
                            <div class="share_btn bg" style="background:#eee" v-if="Number(settingDate.inviter_count)>=Number(settingDate.config.shareholder_inviter_1)">
                                <div>下一步</div>
                            </div>
                        </div>
                        <div  v-if="Number(settingDate.inviter_count)<Number(settingDate.config.shareholder_inviter_1)">
                            <div style="margin-top:20px;color:#fff" class="infoText">经系统检测,您当前下级人数不足,无法申请</div>
                            <div class="share_btn bg" style="background:#eee">
                                <div>下一步</div>
                            </div>
                        </div>
                     </div>
                        
                        <div class="share_btn" v-if="Number(userFrom.member.available_assets)>=Number(settingDate.config.shareholder_assets_1)&&Number(settingDate.inviter_count)>=Number(settingDate.config.shareholder_inviter_1)" @click="nethBtn">
                            <img src="../../assets/nextIcon.png"/>
                        </div>
                </div>
                <div v-else>
                    <!-- 判断是否达标 -->
                     <div v-if="Number(userFrom.member.available_assets)<Number(settingDate.shareholder_activity.activity_assets_1)&&Number(settingDate.inviter_count)<Number(settingDate.shareholder_activity.activity_inviter_1)">
                         <div>
                            <div style="margin-top:20px;color:#fff" class="infoText">经系统检测,您当前不满足申请条件,无法申请</div>
                            <div class="share_btn bg" style="background:#eee">
                                <div>下一步</div>
                            </div>
                        </div>
                     </div>
                     <div v-else>
                          <!-- 判断是否达标 -->
                        <div  v-if="Number(userFrom.member.available_assets)<Number(settingDate.shareholder_activity.activity_assets_1)">
                            <div style="margin-top:20px;color:#fff" class="infoText">经系统检测,您当前补金不足,无法申请</div>
                            <div class="share_btn bg" style="background:#eee">
                                <div>下一步</div>
                            </div>
                        </div>
                        <div  v-if="Number(settingDate.inviter_count)<Number(settingDate.shareholder_activity.activity_inviter_1)">
                            <div style="margin-top:20px;color:#fff" class="infoText">经系统检测,您当前下级人数不足,无法申请</div>
                            <div class="share_btn bg" style="background:#eee" v-if="Number(settingDate.inviter_count)>=Number(settingDate.shareholder_activity.activity_inviter_1)">
                                <div>下一步</div>
                            </div>
                        </div>
                     </div>
                    
                        <div class="share_btn" v-if="Number(userFrom.member.available_assets)>=Number(settingDate.shareholder_activity.activity_assets_1)&&Number(settingDate.inviter_count)>=Number(settingDate.shareholder_activity.activity_inviter_1)" @click="nethBtn">
                            <img src="../../assets/nextIcon.png"/>
                        </div>
                </div>

                
            </div>
            <!-- 置换式 -->
            <div v-else>
                <div v-if="!settingDate.shareholder_activity">
                    
                     <!-- 判断是否达标 -->
                        <div  v-if="Number(userFrom.member.available_assets)<Number(settingDate.config.shareholder_assets_2)">
                            <div style="margin-top:20px;color:#fff" class="infoText">经系统检测,您当前补金不足,无法申请</div>
                            <div class="share_btn bg" style="background:#eee" v-if="Number(settingDate.inviter_count)>=Number(settingDate.config.shareholder_inviter_2)">
                                <div>下一步</div>
                            </div>
                        </div>
                        <!-- <div  v-if="Number(settingDate.inviter_count)<Number(settingDate.config.shareholder_inviter_2)">
                            <div style="margin-top:20px;color:#fff" class="infoText">经系统检测,您当前下级人数不足,无法申请</div>
                            <div class="share_btn bg" style="background:#eee">
                                <div>下一步</div>
                            </div>
                        </div> -->
                        <div class="share_btn" @click="nethBtn" v-if="Number(userFrom.member.available_assets)>=Number(settingDate.config.shareholder_assets_2)">
                            <img src="../../assets/nextIcon.png"/>
                        </div>
                </div>
                <div v-else>
                     <!-- 判断是否达标 -->
                        <div  v-if="Number(userFrom.member.available_assets)<Number(settingDate.shareholder_activity.activity_assets_2)">
                            <div style="margin-top:20px;color:#fff" class="infoText">经系统检测,您当前补金不足,无法申请</div>
                            <div class="share_btn bg" style="background:#eee" v-if="Number(settingDate.inviter_count)>=Number(settingDate.shareholder_activity.activity_inviter_2)">
                                <div>下一步</div>
                            </div>
                        </div>
                        <!-- <div  v-if="Number(settingDate.inviter_count)<Number(settingDate.shareholder_activity.activity_inviter_2)">
                            <div style="margin-top:20px;color:#fff" class="infoText">经系统检测,您当前下级人数不足,无法申请</div>
                            <div class="share_btn bg" style="background:#eee">
                                <div>下一步</div>
                            </div>
                        </div> -->
                        <div class="share_btn" v-if="Number(userFrom.member.available_assets)>=Number(settingDate.shareholder_activity.activity_assets_2)" @click="nethBtn">
                            <img src="../../assets/nextIcon.png"/>
                        </div>
                </div>
            </div>
            
        </div>
        <toast v-if="toastShow" :config="configData"></toast>
    </div>
</template>
<script>
import { getSetting,getuser_api} from "@/api/common";
// import wx from "weixin-js-sdk";
import toast from "@/components/toast";
    export default{
        data(){
            return {
                selectShow:1,
                settingDate:{
                    config:{

                    },
                    shareholder_activity:{
                        activity_assets_1:""
                    }
                },
                checked:"1",
                userFrom:{
                    member:{}
                },
                 configData: {
                  title: ""
                },
                toastShow: false,
            }
        },
        async created(){
            await this.getConfig();
            await this.getUser()
        },
        components: {
             toast: toast,
        },
        methods: {
            getUser(){
                getuser_api().then(res =>{
                    console.log(res,"res");
                    if(res.code==0){
                        this.userFrom = res.data
                        this.$forceUpdate()
                    }
                })
            },
            mineMessage() {
                // wx.closeWindow();
                this.$router.push("../lador/service")
            },
            selectBtn(index){
                this.selectShow = index
            },
            nethBtn(){
                // if(this.settingDate.shareholder_activity){
                //     if(this.checked==1){
                //         if(this.userFrom.inviter_count<this.settingDate.shareholder_activity.activity_inviter_1||this.userFrom.member.available_assets<this.settingDate.shareholder_activity.activity_assets_1){
                //             this.toastShow = true;
                //             this.configData.title = "用户不满足申请条件";
                //             setTimeout(() => {
                //               this.toastShow = false;
                //             }, 1000);
                //             return false;
                //         }

                //     }else{
                //         if(this.userFrom.inviter_count<this.settingDate.shareholder_activity.activity_inviter_2||this.userFrom.member.available_assets<this.settingDate.shareholder_activity.activity_assets_2){
                //             this.toastShow = true;
                //             this.configData.title = "用户不满足申请条件";
                //             setTimeout(() => {
                //               this.toastShow = false;
                //             }, 1000);
                //             return false;
                //         }
                //     }
                // }else{
                //     if(this.checked==1){
                //         if(this.userFrom.inviter_count<this.settingDate.config.shareholder_inviter_1||this.userFrom.member.available_assets<this.settingDate.config.shareholder_assets_1){
                //             this.toastShow = true;
                //             this.configData.title = "用户不满足申请条件";
                //             setTimeout(() => {
                //               this.toastShow = false;
                //             }, 1000);
                //             return false;
                //         }

                //     }else{
                //         if(this.userFrom.inviter_count<this.settingDate.config.shareholder_inviter_2||this.userFrom.member.available_assets<this.settingDate.config.shareholder_assets_2){
                //             this.toastShow = true;
                //             this.configData.title = "用户不满足申请条件";
                //             setTimeout(() => {
                //               this.toastShow = false;
                //             }, 1000);
                //             return false;
                //         }
                //     }
                // }
                
                
                if(this.selectShow==2){
                     this.$router.push(`../shareholders/offline/index?status=true&select=${this.checked}`)
                }else{
                    this.$router.push(`../shareholders/online/onlineDetails?select=${this.checked}`)
                }
               
            },
            getConfig() {
                getSetting().then(res=>{
                    console.log(res.status)
                    if(res.code==0){
                        this.settingDate = res.data;
                        this.$forceUpdate()
                    }
                })
            }
        }    
    }
</script>
<style type="text/css">
van-radio{
    margin-right: 30px
}
    .text_color{
        color:#fff;
        font-size: 30px;margin-right: 60px;
        margin-left: 20px
    }
    .contaners_share{
        width: 100%;
        /* height:100vh; */
        position: relative;
        font-size: 30px;
        color: #DEB77A;
    }
    .content_share1{
        position: absolute;
        top: 0;left: 46px;
        width:666px
    }
    .contaners_share .bg{
        border-radius: 30px;
        text-align: center;
        line-height:75px;
        
    }
    .messageTitle12 .messageIcon {
  width: 36px;
  height: 36px;
  margin-right: 20px;
}
.messageTitle12 {
  text-align: right;
  margin-right: 20px;
  font-size: 20px;
  margin-top: 20px;
  color: #fff;
  position: absolute;
  top: 10px;right: 10px
}

.title_share{
    font-size: 35px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #DEB77A;
    opacity: 1;
    margin-top: 70px
}
.share_box{
    width: 666px;
height: 570px;
border: 2px solid #DEB77A;
opacity: 1;
margin: 27px auto 34px; 
font-size: 25px;
color:#DEB77A;
padding: 25px 14px;box-sizing: border-box;
}
.share_box div{
    line-height:80px
}

.share_text{
    margin-bottom: 20px
}
.share_text1{
    font-size: 25px;margin-bottom: 40px;
    color: #fff
}
.select_share{
    display: flex;
    margin-top: 20px
}
.select_share img{
    width:34px;
    height: 29px
}
.select_img{
    width: 175px;
    height: 43px;
    line-height:43px;
    border: 2px solid #DEB77A;
    opacity: 1;
    border-radius: 29px;
    font-size: 25px;
    padding-left: 20px;box-sizing: border-box;
    display: flex;
    
}
.share_text2{
    margin: 0 33px 0 10px
}
.share_btn{
    width: 206px;
    height:75px;
    margin: 50px auto 0
}
.share_btn img{
    width: 100%;
    height: 100%
}
.select_color{
    width:20px;height: 30px;
    margin: 5px 0 0 50px  
}
.selectBtn{
    margin: 20px 0;
    font-size:35px;
    line-height:30px
}
.switherclass{
    margin: 20px 20px 0
}
.infoText{
    color:#fff;font-size:25px;text-align: center;
}
</style>